import React, { useEffect, useState } from "react";
import { Container, Row, Col, Form, Card, Button } from "react-bootstrap";
import axios from "axios";
import { url } from "../../config";
import { useAuth } from "../Contexts/MyContext";
import PCSpecModal from "../Modals/PCSpecModal";
import {
  FaGamepad,
  FaRobot,
  FaBuilding,
  FaCamera,
  FaChartLine,
} from "react-icons/fa";

const PreBuiltSelector = () => {
  const [selectedIcon, setSelectedIcon] = useState("gaming");
  const [useCase, setUseCase] = useState("gaming");
  const { authContext, user, setUser } = useAuth();
  const [price, setPrice] = useState(30000);
  const [errMsg, setErrMsg] = useState("");

  const [pcs, setPcs] = useState([]);
  const [pc, setPc] = useState();

  const [showPc, setShowPc] = useState(false);
  const handleClosePc = () => {
    setShowPc(false);
  };

  useEffect(() => {
    if (pcs.length > 0) window.scrollTo(0, 600);
  }, [pcs.length]);

  const handleSubmit = () => {
    setErrMsg("");
    if (!useCase) {
      setErrMsg("Please Select Use Case");
      return;
    }
    fetchPreBuilt();
  };

  const fetchPreBuilt = async () => {
    try {
      const res = await axios.post(
        url + "/fetchprebuilt",
        { useCase, price },
        { withCredentials: true }
      );
      setPcs(res.data);
      window.scrollTo(0, 600);
    } catch (error) {
      console.log(error);
    }
  };

  const handleClick = (usage) => {
    setSelectedIcon(usage);
    setUseCase(usage);
  };

  const sendToCart = (prebuilt, index) => {
    if (authContext != null) {
      if (
        user.cartInfo.filter((i) => {
          return i.itemShort === prebuilt.pcShort;
        }).length === 0
      ) {
        addToCart(prebuilt.pcShort, prebuilt.pc, index);
      } else {
        setPcs((prevPc) =>
          prevPc.map((item, i) =>
            i === index
              ? { ...item, message: "Item already added to cart!" }
              : item
          )
        );
        //setMsg("Item already added to cart!");
        setTimeout(() => {
          setPcs((prevPc) =>
            prevPc.map((item, i) =>
              i === index ? { ...item, message: "" } : item
            )
          );
          //setMsg("");
        }, 2000);
      }
    } else {
      setPcs((prevPc) =>
        prevPc.map((item, i) =>
          i === index
            ? { ...item, message: "Please login to add to cart!" }
            : item
        )
      );
      //setMsg("Please login to add to cart!");
      setTimeout(() => {
        setPcs((prevPc) =>
          prevPc.map((item, i) =>
            i === index ? { ...item, message: "" } : item
          )
        );
        //setMsg("");
      }, 2000);
    }
  };
  const addToCart = async (itemShort, item, index) => {
    try {
      const res = await axios.post(
        url + "/addToCart",
        {
          userid: user.userInfo[0].id,
          item,
          itemType: "PC",
          itemShort,
          qty: 1,
          stock: "Y",
        },
        { withCredentials: true }
      );
      setUser({ ...user, cartInfo: res.data });
      setPcs((prevPc) =>
        prevPc.map((item, i) =>
          i === index ? { ...item, message: "Item added to cart!" } : item
        )
      );
      //setMsg("Item added to cart!");
      setTimeout(() => {
        setPcs((prevPc) =>
          prevPc.map((item, i) =>
            i === index ? { ...item, message: "" } : item
          )
        );
        //setMsg("");
      }, 2000);
    } catch (err) {
      console.log(err);
    }
  };

  const title = (price) => {
    if (price < 60000) {
      return "Fusion Gaming Elite PC";
    } else if (price > 59999 && price < 120000) {
      return "Fusion Gaming Pro PC";
    } else if (price > 119999) {
      return "Fusion Gaming Master PC";
    }
  };

  const icons = [
    { useCase: "gaming", icon: <FaGamepad />, label: "Gaming" },
    { useCase: "ai", icon: <FaRobot />, label: "AI/ML" },
    { useCase: "architecture", icon: <FaBuilding />, label: "Architecture" },
    { useCase: "creator", icon: <FaCamera />, label: "Editing" },
    { useCase: "trader", icon: <FaChartLine />, label: "Trading" },
  ];

  return (
    <Container className="my-5">
      <Row className="justify-content-center my-5">
        <Col
          lg={8}
          xs={12}
          className="border border-dark rounded"
          style={{ backgroundColor: "#151515" }}
        >
          <h1 className="my-4 text-center text-danger">Pre-Builts</h1>
          <Row className="mb-3">
            <Col md={12} className="mb-2 text-center">
              <Form.Label className="text-danger">Select Use Case</Form.Label>
              <Row className="text-center">
                {icons.map((item) => (
                  <Col key={item.useCase}>
                    <div
                      onClick={() => handleClick(item.useCase)}
                      style={{
                        cursor: "pointer",
                        color:
                          selectedIcon === item.useCase ? "#dc3545" : "#fff",
                        fontSize: "2rem",
                        margin: "10px 0",
                      }}
                    >
                      {item.icon}
                    </div>
                    <div
                      style={{
                        color:
                          selectedIcon === item.useCase ? "#dc3545" : "#fff",
                        fontSize: "1rem",
                      }}
                    >
                      {item.label}
                    </div>
                  </Col>
                ))}
              </Row>
            </Col>
            <Col className="mb-2 mt-4 text-center">
              <Form.Label className="text-danger">Budget</Form.Label>
              <Form.Range
                value={price}
                onChange={(e) => setPrice(e.target.value)}
                className="custom-slider"
                min={30000}
                max={300000}
                step={5000}
              />
              <p className="text-light">
                {"₹ " + new Intl.NumberFormat("en-IN").format(price) + "/-"}
              </p>
            </Col>
          </Row>
          <Row className="text-danger justify-content-center mb-3">
            {errMsg}
          </Row>
          <Row className="justify-content-center mb-5">
            <Col className="d-flex justify-content-center">
              <Button variant="danger" onClick={handleSubmit}>
                Find My PC
              </Button>
            </Col>
          </Row>
        </Col>
      </Row>
      <Row className="justify-content-center my-5">
        {pcs.map((pc, i) => (
          <Row key={i} className="justify-content-center">
            <Col
              lg={8}
              className="border mt-5 p-2 border-dark rounded"
              style={{ backgroundColor: "#151515" }}
            >
              <Card border="danger" style={{ backgroundColor: "#151515" }}>
                <Row>
                  <Col md={3} xs={12}>
                    <Card.Img
                      src={
                        "/cabinet/" +
                        JSON.parse(pc.pc).cabinetBrand +
                        "/" +
                        JSON.parse(pc.pc).cabinetBrand +
                        " " +
                        JSON.parse(pc.pc).cabinetModel +
                        ".png"
                      }
                      alt="PC"
                      className="img-fluid"
                    />
                  </Col>
                  <Col md={9} xs={12}>
                    <Card.Body>
                      <Card.Title className="text-danger mb-2">
                        {title(pc.updatedPrice)}
                      </Card.Title>
                      <Card.Text className="text-secondary">
                        <small className="text-light">{pc.pcShort}</small>
                      </Card.Text>
                    </Card.Body>
                    <Row className="mb-2">
                      <Col md={5} className="text-danger my-1 ms-2">
                        <h5>
                          {"Price: ₹ " +
                            new Intl.NumberFormat("en-IN").format(
                              pc.updatedPrice.toFixed(0)
                            ) +
                            "/-"}
                        </h5>
                      </Col>
                      <Col md={12} className="d-flex justify-content-between">
                        <Button
                          variant="outline-danger"
                          onClick={() => {
                            setShowPc(true);
                            setPc(JSON.parse(pc.pc));
                          }}
                          className="mx-2"
                        >
                          PC Info
                        </Button>
                        <Button
                          variant="danger"
                          onClick={(e) => sendToCart(pc, i)}
                          className="me-2"
                        >
                          Add To Cart
                        </Button>
                      </Col>
                      <Col xxl={12} className="text-center text-danger mt-3">
                        {pc.message}
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Card>
            </Col>
          </Row>
        ))}
      </Row>
      {pc ? <PCSpecModal show={showPc} onHide={handleClosePc} pc={pc} /> : ""}
    </Container>
  );
};

export default PreBuiltSelector;
