import React from "react";
import { Container, Row, Col, Image, Button } from "react-bootstrap";
import { Link } from "react-router-dom";

const HomeSection = () => {
  const sectionData = [
    {
      image: "/Untitled-1.jpg",
      heading: "Enjoy Peak Performance",
      text: "Reach peak performance with our expertly crafted prebuilt PCs. Our team meticulously evaluates every component to ensure the perfect balance of quality and cost-efficiency. We carefully select and combine the best parts to deliver exceptional performance while optimizing your investment. It’s not just about building PCs, it’s about getting the best possible results for every rupee spent.",
      button: "Shop Pre-Builts",
    },
    {
      image: "/Untitled-2.jpg",
      heading: "Perfect Match For Everyone",
      text: "Tailored to Your Unique Needs: Whether you're a gaming enthusiast or a professional seeking a high-performance machine, our custom-built PCs are designed to match your exact requirements. Select each component with ease using India’s first flawless tool, ensuring a hassle-free PC-building experience without any compatibility issues.",
      button: "Build Your Own PC",
    },
    {
      image: "/Untitled-3.jpg",
      heading: "Consult, Customize, and Deliver – All in 24 Hours!",
      text: "We provide personalized consultations, both via in-call screen sharing and in-store, where our experts guide you in choosing the perfect products and configurations for your needs. Enjoy free delivery across India with dispatch guaranteed within 24 hours, ensuring a fast and seamless experience. Whether you visit us in person or reach out to us online, we’re committed to delivering top-notch service and support.",
      button: "Talk to A Techie",
    },
  ];

  return (
    <div style={{ backgroundColor: "rgb(15, 15, 15)", height: "100%" }}>
      <Container>
        {sectionData.map((item, index) => (
          <Row className="mb-5" key={index}>
            <Col
              lg={8}
              className={index % 2 === 0 ? "my-3" : "order-lg-2 mb-3"}
            >
              <Image src={item.image} alt="Section Image" fluid />
            </Col>
            <Col lg={4} className="d-flex align-items-center text-light mb-3">
              <Row className="align-items-center">
                <h2 className="text-danger">{item.heading}</h2>
                <p>{item.text}</p>
                <Button
                  className="ms-3 mt-2"
                  as={Link}
                  to={
                    index === 0
                      ? "/prebuilts"
                      : index === 1
                      ? "/config"
                      : "/contact-us"
                  }
                  variant="danger"
                  size="md"
                  style={{ width: "40%" }}
                >
                  {item.button}
                </Button>
              </Row>
            </Col>
          </Row>
        ))}
      </Container>
    </div>
  );
};

export default HomeSection;
