import React, { useState } from "react";
import axios from "axios";
import { Modal, Button, Form, Row, Col } from "react-bootstrap";
import { url } from "../../config";
import { useAuth } from "../Contexts/MyContext";

const VerifyPhone = ({ show, onHide }) => {
  const [otp, setOtp] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const { user, setUser } = useAuth();
  const { firstname, lastname, dob, gender, number, email, gst } =
    user.userInfo[0];

  async function updateUser() {
    try {
      const res = await axios.post(
        url + "/updateuser",
        {
          userId: user.userInfo[0].id,
          firstName: firstname,
          lastName: lastname,
          number,
          email,
          dob,
          gender,
          gst,
          verified: 1,
        },
        { withCredentials: true }
      );
      setUser({ ...user, userInfo: res.data });
      setErrorMessage("OTP Verified");
      setTimeout(() => {
        onHide();
      }, 2000);
    } catch (err) {
      console.log(err);
    }
  }

  const verify = async () => {
    try {
      const res = await axios.post(
        url + "/verifyotp",
        { phoneNumber: "+91" + number, otp: otp },
        { withCredentials: true }
      );
      if (res.data === "approved") {
        updateUser();
      } else {
        setTimeout(() => {
          setOtp("");
          setErrorMessage("Wrong OTP!!");
          setIsLoading(false);
        }, 2000);
        setTimeout(() => {
          setErrorMessage("");
        }, 4000);
      }
    } catch (err) {
      console.log(err);
    }
  };
  const resend = async () => {
    try {
      await axios.post(
        url + "/sendotp",
        { phoneNumber: "+91" + number },
        { withCredentials: true }
      );
    } catch (err) {
      console.log(err);
    }
  };

  const handleOTPChange = (e) => {
    setOtp(e.target.value);
  };
  const handleOTPSubmit = (e) => {
    e.preventDefault();
    if (otp === "") {
      setIsLoading(true);
      setTimeout(() => {
        setOtp("");
        setErrorMessage("OTP cannot be blank!");
        setIsLoading(false);
      }, 2000);
      setTimeout(() => {
        setErrorMessage("");
      }, 4000);
      return;
    } else {
      setIsLoading(true);
      verify();
    }
  };
  const handleOTPResend = (e) => {
    e.preventDefault();
    resend();
  };

  return (
    <Modal show={show} onHide={onHide}>
      <Modal.Header style={{ backgroundColor: "#151515" }}>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            width: "100%",
          }}
        >
          <Modal.Title className="text-danger">Enter OTP</Modal.Title>
          <button
            className="custom-close"
            onClick={(e) => {
              onHide();
            }}
            style={{
              border: "none",
              background: "transparent",
              fontSize: "1.5rem",
              cursor: "pointer",
              color: "#fff",
            }}
          >
            &times;
          </button>
        </div>
      </Modal.Header>
      <Modal.Body style={{ backgroundColor: "#151515" }}>
        <Form onSubmit={handleOTPSubmit}>
          <Form.Group controlId="otp">
            <Form.Label className="text-light">
              Enter the OTP that was sent to your number:{" "}
              <span className="text-danger">{number}</span>
            </Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter OTP"
              value={otp}
              onChange={handleOTPChange}
              maxLength={6}
            />
          </Form.Group>

          {errorMessage && <p className="text-danger mt-3">{errorMessage}</p>}

          <Row className="mt-3">
            <Col className="col-xs-6 col-sm-3">
              <Button
                variant="danger"
                type="submit"
                disabled={isLoading}
                onClick={handleOTPSubmit}
              >
                {isLoading ? "Verifying..." : "Verify OTP"}
              </Button>
            </Col>
            <Col className="col-xs-6 col-sm-3">
              <Button variant="danger" type="submit" onClick={handleOTPResend}>
                Resend
              </Button>
            </Col>
          </Row>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default VerifyPhone;
