import React, { useState, useEffect } from "react";
import {
  Container,
  Row,
  Col,
  Button,
  Card,
  Nav,
  NavItem,
  NavLink,
  Offcanvas,
} from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import {
  FaBars,
  FaUser,
  FaBox,
  FaMapMarkerAlt,
  FaTag,
  FaSignOutAlt,
} from "react-icons/fa";
import axios from "axios";
import { url } from "../../config";
import { useAuth } from "../Contexts/MyContext";
import { BiSupport } from "react-icons/bi";

const MyCoupons = () => {
  const navigate = useNavigate();
  const { user, setAuthContext, setUser } = useAuth();
  const [showOffcanvas, setShowOffcanvas] = useState(false);
  const handleToggleOffcanvas = () => setShowOffcanvas(!showOffcanvas);

  useEffect(() => {
    const fetchUserData = async (uid) => {
      try {
        const res = await axios.post(
          url + "/fetchAllUserDetails",
          { id: uid },
          { withCredentials: true }
        );
        setUser(res.data);
      } catch (err) {
        console.log(err);
      }
    };
    fetchUserData(user.userInfo[0].id);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const handleLogout = async (e) => {
    setAuthContext(null);
    setUser(null);
    try {
      await axios.post(url + "/clearCookie", {
        withCredentials: true,
      });
    } catch (err) {
      console.log(err);
    }
    navigate("/");
  };

  const CouponCard = ({
    couponId,
    description,
    value,
    validity,
    createdDate,
    state,
  }) => {
    return (
      <Card
        className="mb-3"
        border={state === "Active" ? "danger" : "dark"}
        style={{ backgroundColor: "#151515" }}
      >
        <Card.Body>
          <Card.Title
            className={state === "Active" ? "text-light" : "text-secondary"}
          >
            <FaTag /> {state === "Active" ? description : <s>{description}</s>}
          </Card.Title>
          <span className="text-secondary">Coupon ID: {couponId}</span>
          <Row className="mt-2">
            <Col md={6}>
              <Card.Text
                className={state === "Active" ? "text-light" : "text-secondary"}
              >
                <span className="text-danger">Value:</span> ₹
                {value < 0
                  ? new Intl.NumberFormat("en-IN").format(value * -1) + "(-)"
                  : new Intl.NumberFormat("en-IN").format(value) + "(+)"}
                <br />
                <span className="text-danger">Validity:</span>{" "}
                {new Date(validity).toLocaleDateString()}{" "}
                <span className="text-danger">({state})</span>
                <br />
                <span className="text-danger">Generated Date:</span>{" "}
                {new Date(createdDate).toLocaleDateString()}
              </Card.Text>
            </Col>
          </Row>
        </Card.Body>
      </Card>
    );
  };

  const OffcanvasMenu = () => (
    <Offcanvas
      show={showOffcanvas}
      onHide={handleToggleOffcanvas}
      placement="start"
    >
      <Offcanvas.Header
        closeButton
        closeVariant="white"
        style={{ backgroundColor: "#151515" }}
      >
        <Offcanvas.Title className="text-danger">Menu</Offcanvas.Title>
      </Offcanvas.Header>
      <Offcanvas.Body style={{ backgroundColor: "#151515" }}>
        <Card
          border="danger"
          className="mb-3"
          style={{ backgroundColor: "#151515" }}
        >
          <Card.Body>
            <Card.Title className="text-danger">Welcome!</Card.Title>
            <Card.Text className="text-light">
              {user.userInfo[0].firstname} <br />
              {user.userInfo[0].email}
            </Card.Text>
          </Card.Body>
        </Card>
        <Nav defaultActiveKey="/home" className="flex-column">
          <NavItem>
            <NavLink
              as={Link}
              onClick={(e) => {
                e.preventDefault();
                handleToggleOffcanvas();
                navigate("/profile");
              }}
              className="link-danger"
            >
              <FaUser /> My Profile
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              as={Link}
              onClick={(e) => {
                e.preventDefault();
                handleToggleOffcanvas();
                navigate("/orders");
              }}
              className="link-danger"
            >
              <FaBox /> My Orders
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              as={Link}
              onClick={(e) => {
                e.preventDefault();
                handleToggleOffcanvas();
                navigate("/addresses");
              }}
              className="link-danger"
            >
              <FaMapMarkerAlt /> My Addresses
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              as={Link}
              onClick={(e) => {
                e.preventDefault();
                handleToggleOffcanvas();
                navigate("/coupons");
              }}
              className="link-danger"
            >
              <FaTag /> My Coupons
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              as={Link}
              onClick={(e) => {
                e.preventDefault();
                handleToggleOffcanvas();
                navigate("/tickets");
              }}
              className="link-danger"
            >
              <BiSupport /> My Tickets
            </NavLink>
          </NavItem>
          <Nav.Item className="mt-3 mx-3">
            <Button variant="danger" onClick={handleLogout}>
              <FaSignOutAlt /> Logout
            </Button>
          </Nav.Item>
        </Nav>
      </Offcanvas.Body>
    </Offcanvas>
  );

  const Sidebar = () => (
    <>
      {" "}
      <Card
        border="danger"
        className="mb-3"
        style={{ backgroundColor: "#151515" }}
      >
        <Card.Body>
          <Card.Title className="text-danger">Welcome!</Card.Title>
          <Card.Text className="text-light">
            {user.userInfo[0].firstname} <br />
            {user.userInfo[0].email}
          </Card.Text>
        </Card.Body>
      </Card>
      <Nav defaultActiveKey="/home" className="flex-column">
        <NavItem>
          <NavLink as={Link} to="/profile" className="link-danger">
            <FaUser /> My Profile
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink as={Link} to="/orders" className="link-danger">
            <FaBox /> My Orders
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink as={Link} to="/addresses" className="link-danger">
            <FaMapMarkerAlt /> My Addresses
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink as={Link} to="/coupons" className="link-danger">
            <FaTag /> My Coupons
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink as={Link} to="/tickets" className="link-danger">
            <BiSupport /> My Tickets
          </NavLink>
        </NavItem>
        <Nav.Item className="mt-3 mx-3">
          <Button variant="danger" onClick={handleLogout}>
            <FaSignOutAlt /> Logout
          </Button>
        </Nav.Item>
      </Nav>
    </>
  );

  return (
    <Container className="mt-5">
      <Row className="mx-2">
        <Col xs={12} className="d-block d-lg-none">
          <Button
            variant="dark"
            onClick={handleToggleOffcanvas}
            aria-controls="offcanvas-sidebar"
            aria-expanded={showOffcanvas}
            className="mb-3"
          >
            <FaBars /> Menu
          </Button>
        </Col>
        <Col
          xs={12}
          className="d-block d-lg-none border border-dark rounded p-3"
          style={{ backgroundColor: "#151515", minHeight: "55vh" }}
        >
          <Row>
            <span className="h5 text-danger mb-3">My Coupons</span>{" "}
          </Row>
          {user.couponInfo.length > 0 ? (
            user.couponInfo.map((coupon, i) => (
              <CouponCard
                key={i}
                couponId={coupon.couponid}
                description={coupon.description}
                value={coupon.value}
                validity={coupon.validity}
                createdDate={coupon.created}
                state={
                  new Date(coupon.validity) > new Date() ? "Active" : "Expired"
                }
              />
            ))
          ) : (
            <Row>No Coupons Found!</Row>
          )}
        </Col>
      </Row>
      <Row className="mx-2">
        <Col
          lg={3}
          className="d-none d-lg-block border border-dark rounded p-3"
          style={{ backgroundColor: "#151515" }}
        >
          <Sidebar />
        </Col>
        <Col
          lg={8}
          className="d-none d-lg-block border border-dark rounded p-3 mx-4"
          style={{ backgroundColor: "#151515", minHeight: "55vh" }}
        >
          <Row>
            <span className="h5 text-danger mb-3">My Coupons</span>{" "}
          </Row>
          {user.couponInfo.length > 0 ? (
            user.couponInfo.map((coupon, i) => (
              <CouponCard
                key={i}
                couponId={coupon.couponid}
                description={coupon.description}
                value={coupon.value}
                validity={coupon.validity}
                createdDate={coupon.created}
                state={
                  new Date(coupon.validity) > new Date() ? "Active" : "Expired"
                }
              />
            ))
          ) : (
            <Row className="p-3 text-secondary">No Coupons Found!</Row>
          )}
        </Col>
      </Row>
      <OffcanvasMenu />
    </Container>
  );
};

export default MyCoupons;
