import React, { useState } from "react";
import {
  Container,
  Row,
  Col,
  Button,
  Card,
  Nav,
  NavItem,
  NavLink,
  Offcanvas,
  Form,
} from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import {
  FaBars,
  FaUser,
  FaBox,
  FaMapMarkerAlt,
  FaTag,
  FaSignOutAlt,
} from "react-icons/fa";
import axios from "axios";
import { url } from "../../config";
import { useAuth } from "../Contexts/MyContext";
import { validPincode } from "../Regex/Regex";
import { BiSupport } from "react-icons/bi";

const AddAddress = () => {
  const navigate = useNavigate();
  const { user, setAuthContext, setUser } = useAuth();
  const [showOffcanvas, setShowOffcanvas] = useState(false);
  const handleToggleOffcanvas = () => setShowOffcanvas(!showOffcanvas);
  const [showAlert, setShowAlert] = useState(false);
  const [error, setError] = useState("");

  const indianStates = [
    "Andhra Pradesh",
    "Arunachal Pradesh",
    "Assam",
    "Bihar",
    "Chhattisgarh",
    "Goa",
    "Gujarat",
    "Haryana",
    "Himachal Pradesh",
    "Jharkhand",
    "Karnataka",
    "Kerala",
    "Madhya Pradesh",
    "Maharashtra",
    "Manipur",
    "Meghalaya",
    "Mizoram",
    "Nagaland",
    "Odisha",
    "Punjab",
    "Rajasthan",
    "Sikkim",
    "Tamil Nadu",
    "Telangana",
    "Tripura",
    "Uttar Pradesh",
    "Uttarakhand",
    "West Bengal",
    "Andaman and Nicobar Islands",
    "Chandigarh",
    "Dadra and Nagar Haveli and Daman and Diu",
    "Lakshadweep",
    "Delhi",
    "Puducherry",
  ];

  const [address, setAddress] = useState({
    title: "",
    addressLine: "",
    city: "",
    state: "",
    pincode: "",
  });

  const handleChange = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    setAddress({ ...address, [name]: value });
  };

  const handleLogout = async (e) => {
    setAuthContext(null);
    setUser(null);
    try {
      await axios.post(url + "/clearCookie", {
        withCredentials: true,
      });
    } catch (err) {
      console.log(err);
    }
    navigate("/");
  };

  const handleSubmit = (e) => {
    if (
      !address.title ||
      !address.addressLine ||
      !address.city ||
      !address.state ||
      !address.pincode
    ) {
      setError("All details are mandatory!");
      setShowAlert(true);
      return;
    }
    if (!validPincode.test(address.pincode)) {
      setError("Please enter valid 6 digit pincode!");
      setShowAlert(true);
      return;
    }
    const add =
      address.addressLine +
      ", " +
      address.city +
      ", " +
      address.state +
      ", " +
      address.pincode +
      ".";
    addAddress(add);
  };

  async function addAddress(add) {
    try {
      const res = await axios.post(
        url + "/addaddress",
        { userid: user.userInfo[0].id, address: add, title: address.title },
        { withCredentials: true }
      );
      setError("Address Added Successfully!!");
      setShowAlert(true);
      setTimeout(() => {
        setShowAlert(false);
        navigate(-1);
      }, 1000);
      setUser({ ...user, addressInfo: res.data });
    } catch (err) {
      console.log(err);
    }
  }

  const OffcanvasMenu = () => (
    <Offcanvas
      show={showOffcanvas}
      onHide={handleToggleOffcanvas}
      placement="start"
    >
      <Offcanvas.Header
        closeButton
        closeVariant="white"
        style={{ backgroundColor: "#151515" }}
      >
        <Offcanvas.Title className="text-danger">Menu</Offcanvas.Title>
      </Offcanvas.Header>
      <Offcanvas.Body style={{ backgroundColor: "#151515" }}>
        <Card
          border="danger"
          className="mb-3"
          style={{ backgroundColor: "#151515" }}
        >
          <Card.Body>
            <Card.Title className="text-danger">Welcome!</Card.Title>
            <Card.Text className="text-light">
              {user.userInfo[0].firstname} <br />
              {user.userInfo[0].email}
            </Card.Text>
          </Card.Body>
        </Card>
        <Nav defaultActiveKey="/home" className="flex-column">
          <NavItem>
            <NavLink
              as={Link}
              onClick={(e) => {
                e.preventDefault();
                handleToggleOffcanvas();
                navigate("/profile");
              }}
              className="link-danger"
            >
              <FaUser /> My Profile
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              as={Link}
              onClick={(e) => {
                e.preventDefault();
                handleToggleOffcanvas();
                navigate("/orders");
              }}
              className="link-danger"
            >
              <FaBox /> My Orders
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              as={Link}
              onClick={(e) => {
                e.preventDefault();
                handleToggleOffcanvas();
                navigate("/addresses");
              }}
              className="link-danger"
            >
              <FaMapMarkerAlt /> My Addresses
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              as={Link}
              onClick={(e) => {
                e.preventDefault();
                handleToggleOffcanvas();
                navigate("/coupons");
              }}
              className="link-danger"
            >
              <FaTag /> My Coupons
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              as={Link}
              onClick={(e) => {
                e.preventDefault();
                handleToggleOffcanvas();
                navigate("/tickets");
              }}
              className="link-danger"
            >
              <BiSupport /> My Tickets
            </NavLink>
          </NavItem>
          <Nav.Item className="mt-3 mx-3">
            <Button variant="danger" onClick={handleLogout}>
              <FaSignOutAlt /> Logout
            </Button>
          </Nav.Item>
        </Nav>
      </Offcanvas.Body>
    </Offcanvas>
  );

  const Sidebar = () => (
    <>
      {" "}
      <Card
        border="danger"
        className="mb-3"
        style={{ backgroundColor: "#151515" }}
      >
        <Card.Body>
          <Card.Title className="text-danger">Welcome!</Card.Title>
          <Card.Text className="text-light">
            {user.userInfo[0].firstname} <br />
            {user.userInfo[0].email}
          </Card.Text>
        </Card.Body>
      </Card>
      <Nav defaultActiveKey="/home" className="flex-column">
        <NavItem>
          <NavLink as={Link} to="/profile" className="link-danger">
            <FaUser /> My Profile
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink as={Link} to="/orders" className="link-danger">
            <FaBox /> My Orders
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink as={Link} to="/addresses" className="link-danger">
            <FaMapMarkerAlt /> My Addresses
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink as={Link} to="/coupons" className="link-danger">
            <FaTag /> My Coupons
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink as={Link} to="/tickets" className="link-danger">
            <BiSupport /> My Tickets
          </NavLink>
        </NavItem>
        <Nav.Item className="mt-3 mx-3">
          <Button variant="danger" onClick={handleLogout}>
            <FaSignOutAlt /> Logout
          </Button>
        </Nav.Item>
      </Nav>
    </>
  );
  return (
    <Container className="mt-5">
      <Row className="mx-2">
        <Col xs={12} className="d-block d-lg-none">
          <Button
            variant="dark"
            onClick={handleToggleOffcanvas}
            aria-controls="offcanvas-sidebar"
            aria-expanded={showOffcanvas}
            className="mb-3"
          >
            <FaBars /> Menu
          </Button>
        </Col>
        <Col
          xs={12}
          className="d-block d-lg-none border border-dark rounded p-3"
          style={{ backgroundColor: "#151515", minHeight: "55vh" }}
        >
          <Row>
            <span className="h5 text-danger mb-3">Add Address</span>{" "}
          </Row>
          <Card border="danger" style={{ backgroundColor: "#151515" }}>
            <Card.Body>
              <Card.Title className="text-danger mb-3">
                Enter Details
              </Card.Title>
              <Form>
                <Row className="mt-3">
                  <Form.Group as={Row} controlId="title">
                    <Form.Label column md={2} className="text-danger">
                      Title
                    </Form.Label>
                    <Col md={10}>
                      <Form.Control
                        type="text"
                        name="title"
                        autoComplete="name"
                        placeholder="Enter nick-name for the address"
                        value={address.title}
                        onChange={handleChange}
                        required
                      />
                    </Col>
                  </Form.Group>
                </Row>
                <Row className="mt-3">
                  <Form.Group as={Row} controlId="addressLine">
                    <Form.Label column md={2} className="text-danger">
                      Address
                    </Form.Label>
                    <Col md={10}>
                      <Form.Control
                        type="text"
                        name="addressLine"
                        autoComplete="address-line1"
                        placeholder="Enter House no., Block no., Street, etc."
                        value={address.addressLine}
                        onChange={handleChange}
                        required
                      />
                    </Col>
                  </Form.Group>
                </Row>
                <Row className="mt-3">
                  <Form.Group as={Row} controlId="city">
                    <Form.Label column md={2} className="text-danger">
                      City
                    </Form.Label>
                    <Col md={10}>
                      <Form.Control
                        type="text"
                        name="city"
                        autoComplete="address-level2"
                        placeholder="Enter City"
                        value={address.city}
                        onChange={handleChange}
                        required
                      />
                    </Col>
                  </Form.Group>
                </Row>
                <Row className="mt-3">
                  <Form.Group as={Row} controlId="state">
                    <Form.Label column md={2} className="text-danger">
                      State
                    </Form.Label>
                    <Col md={10}>
                      <Form.Control
                        as="select"
                        name="state"
                        autoComplete="address-level1"
                        value={address.state}
                        onChange={handleChange}
                        required
                      >
                        <option disabled value="">
                          Select State
                        </option>
                        {indianStates.map((state, index) => (
                          <option key={index} value={state}>
                            {state}
                          </option>
                        ))}
                      </Form.Control>
                    </Col>
                  </Form.Group>
                </Row>
                <Row className="mt-3">
                  <Form.Group as={Row} controlId="pincode">
                    <Form.Label column md={2} className="text-danger">
                      Pincode
                    </Form.Label>
                    <Col md={10}>
                      <Form.Control
                        type="text"
                        name="pincode"
                        autoComplete="postal-code"
                        placeholder="Enter 6 digit PIN"
                        value={address.pincode}
                        onChange={handleChange}
                        required
                        maxLength={6}
                      />
                    </Col>
                  </Form.Group>
                </Row>
                {showAlert && (
                  <Row>
                    <Col className="h5 mt-3 text-danger">{error}</Col>
                  </Row>
                )}
                <Row>
                  <Col className="mt-4">
                    <Button variant="danger" onClick={handleSubmit}>
                      Submit
                    </Button>
                  </Col>
                </Row>
              </Form>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <Row className="mx-2">
        <Col
          lg={3}
          className="d-none d-lg-block border border-dark rounded p-3"
          style={{ backgroundColor: "#151515" }}
        >
          <Sidebar />
        </Col>
        <Col
          lg={8}
          className="d-none d-lg-block border border-dark rounded p-3 mx-4"
          style={{ backgroundColor: "#151515", minHeight: "55vh" }}
        >
          <Row>
            <span className="h5 text-danger mb-3">Add Address</span>{" "}
          </Row>
          <Card border="danger" style={{ backgroundColor: "#151515" }}>
            <Card.Body>
              <Card.Title className="text-danger mb-3">
                Enter Details
              </Card.Title>
              <Form>
                <Row className="mt-3">
                  <Form.Group as={Row} controlId="title">
                    <Form.Label column md={2} className="text-danger">
                      Title
                    </Form.Label>
                    <Col md={10}>
                      <Form.Control
                        type="text"
                        name="title"
                        autoComplete="name"
                        placeholder="Enter nick-name for the address"
                        value={address.title}
                        onChange={handleChange}
                        required
                      />
                    </Col>
                  </Form.Group>
                </Row>
                <Row className="mt-3">
                  <Form.Group as={Row} controlId="addressLine">
                    <Form.Label column md={2} className="text-danger">
                      Address
                    </Form.Label>
                    <Col md={10}>
                      <Form.Control
                        type="text"
                        name="addressLine"
                        autoComplete="address-line1"
                        placeholder="Enter House no., Block no., Street, etc."
                        value={address.addressLine}
                        onChange={handleChange}
                        required
                      />
                    </Col>
                  </Form.Group>
                </Row>
                <Row className="mt-3">
                  <Form.Group as={Row} controlId="city">
                    <Form.Label column md={2} className="text-danger">
                      City
                    </Form.Label>
                    <Col md={10}>
                      <Form.Control
                        type="text"
                        name="city"
                        autoComplete="address-level2"
                        placeholder="Enter City"
                        value={address.city}
                        onChange={handleChange}
                        required
                      />
                    </Col>
                  </Form.Group>
                </Row>
                <Row className="mt-3">
                  <Form.Group as={Row} controlId="state">
                    <Form.Label column md={2} className="text-danger">
                      State
                    </Form.Label>
                    <Col md={10}>
                      <Form.Control
                        as="select"
                        name="state"
                        autoComplete="address-level1"
                        value={address.state}
                        onChange={handleChange}
                        required
                      >
                        <option disabled value="">
                          Select State
                        </option>
                        {indianStates.map((state, index) => (
                          <option key={index} value={state}>
                            {state}
                          </option>
                        ))}
                      </Form.Control>
                    </Col>
                  </Form.Group>
                </Row>
                <Row className="mt-3">
                  <Form.Group as={Row} controlId="pincode">
                    <Form.Label column md={2} className="text-danger">
                      Pincode
                    </Form.Label>
                    <Col md={10}>
                      <Form.Control
                        type="text"
                        name="pincode"
                        autoComplete="postal-code"
                        placeholder="Enter 6 digit PIN"
                        value={address.pincode}
                        onChange={handleChange}
                        required
                        maxLength={6}
                      />
                    </Col>
                  </Form.Group>
                </Row>
                {showAlert && (
                  <Row>
                    <Col className="h5 mt-3 text-danger">{error}</Col>
                  </Row>
                )}
                <Row>
                  <Col className="mt-4">
                    <Button variant="danger" onClick={handleSubmit}>
                      Submit
                    </Button>
                  </Col>
                </Row>
              </Form>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <OffcanvasMenu />
    </Container>
  );
};

export default AddAddress;
