import React, { useRef, useEffect, useState } from "react";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser, faShoppingCart } from "@fortawesome/free-solid-svg-icons";
import { Link, useLocation } from "react-router-dom";
import { useAuth } from "../Contexts/MyContext";

const Header = () => {
  const [navbarColor, setNavbarColor] = useState("transparent");
  const location = useLocation();
  const isHomePage = location.pathname === "/";
  const { user, authContext } = useAuth();
  const headerRef = useRef(null);
  const prevScrollPos = useRef(window.scrollY);

  const storedValue = () => {
    return authContext;
  };
  const [expanded, setExpanded] = useState(false);
  const handleBrandClick = () => {
    if (window.innerWidth < 992) {
      setExpanded(false);
    }
  };

  const handleNavClick = () => {
    if (window.innerWidth < 992) {
      setExpanded(!expanded);
    }
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const handleClickOutside = (event) => {
    if (headerRef.current && !headerRef.current.contains(event.target)) {
      setExpanded(false);
    }
  };

  useEffect(() => {
    const handleScroll = () => {
      const currentScrollPos = window.scrollY;
      if (window.scrollY > 0.8 * window.innerHeight) {
        setNavbarColor("#000000");
      } else {
        setNavbarColor("transparent");
      }
      if (
        currentScrollPos - prevScrollPos.current > 20 &&
        currentScrollPos > 100
      ) {
        setExpanded(false);
      }
      prevScrollPos.current = currentScrollPos;
    };
    window.addEventListener("scroll", handleScroll);
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      window.removeEventListener("scroll", handleScroll);
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <Navbar
      ref={headerRef}
      expanded={expanded}
      expand="lg"
      variant="dark"
      className={
        isHomePage
          ? "navbar-home sticky-top px-3"
          : "navbar-others sticky-top px-3 bg-black"
      }
      style={{
        backgroundColor: navbarColor,
        transition: "background-color 0.3s",
      }}
    >
      <Navbar.Brand>
        <Link to="/" onClick={handleBrandClick}>
          <img
            src="/FG_Logo.ico"
            className="d-block w-10"
            width={85}
            height={80}
            alt="1"
          />
        </Link>
      </Navbar.Brand>
      <Navbar.Toggle aria-controls="navbar-nav" onClick={handleNavClick} />
      <Navbar.Collapse id="navbar-nav" className="justify-content-end">
        <Nav className="ms-auto">
          <Nav.Link
            as={Link}
            to="/config"
            onClick={handleNavClick}
            className="link-light link-underline-opacity-0"
            eventKey="1"
          >
            <big>Build Your Own PC</big>
          </Nav.Link>
          <Nav.Link
            as={Link}
            to="/prebuilts"
            onClick={handleNavClick}
            className="link-light link-underline-opacity-0"
            eventKey="2"
          >
            <big>Pre-Builts</big>
          </Nav.Link>
          <Nav.Link
            as={Link}
            to="/accessories"
            onClick={handleNavClick}
            className="link-light link-underline-opacity-0"
            eventKey="3"
          >
            <big>Add-Ons</big>
          </Nav.Link>
          <Nav.Link
            as={Link}
            to={storedValue() ? "/tickets" : "/contact-us"}
            onClick={handleNavClick}
            className="link-light link-underline-opacity-0"
            eventKey="4"
          >
            <big>Support</big>
          </Nav.Link>
          {storedValue() ? (
            <Nav.Link
              as={Link}
              to="/profile"
              onClick={handleNavClick}
              className="link-danger link-underline-opacity-0"
              eventKey="5"
            >
              <big>{user?.userInfo[0]?.firstname}</big>
            </Nav.Link>
          ) : (
            <Nav.Link
              as={Link}
              to="/login"
              onClick={handleNavClick}
              className="icon-small"
              eventKey="6"
            >
              <FontAwesomeIcon icon={faUser} size="lg" className="text-light" />
            </Nav.Link>
          )}
          <Nav.Link
            as={Link}
            to={storedValue() ? "/cart" : "/login"}
            onClick={handleNavClick}
            className="icon-small"
            eventKey="7"
          >
            <FontAwesomeIcon
              icon={faShoppingCart}
              size="lg"
              className={storedValue() ? "text-danger" : "text-light"}
            />
            {storedValue() ? (
              <span className="text-danger">
                {user?.cartInfo?.length > 0
                  ? "(" + user?.cartInfo?.length + ")"
                  : ""}
              </span>
            ) : (
              ""
            )}
          </Nav.Link>
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  );
};

export default Header;
