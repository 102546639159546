import React, { useState } from "react";
import {
  Container,
  Row,
  Col,
  Button,
  Card,
  Nav,
  NavItem,
  NavLink,
  Offcanvas,
  ListGroup,
} from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import {
  FaBars,
  FaUser,
  FaBox,
  FaMapMarkerAlt,
  FaTag,
  FaSignOutAlt,
  FaPhone,
  FaEnvelope,
  FaBirthdayCake,
  FaRegFileAlt,
} from "react-icons/fa";
import { VscVerified, VscUnverified } from "react-icons/vsc";
import axios from "axios";
import { url } from "../../config";
import { useAuth } from "../Contexts/MyContext";
import VerifyPhone from "./VerifyPhone";
import { BiSupport } from "react-icons/bi";

const MyProfile = () => {
  const navigate = useNavigate();
  const { user, setAuthContext, setUser } = useAuth();
  const [showOffcanvas, setShowOffcanvas] = useState(false);
  const [showOtp, setShowOtp] = useState(false);
  const { firstname, lastname, dob, gender, number, email, verified, gst } =
    user.userInfo[0];

  const handleToggleOffcanvas = () => setShowOffcanvas((prev) => !prev);

  const handleCloseOtp = () => {
    setShowOtp(false);
  };

  const handleLogout = async (e) => {
    setAuthContext(null);
    setUser(null);
    try {
      await axios.post(url + "/clearCookie", {
        withCredentials: true,
      });
    } catch (err) {
      console.log(err);
    }
    navigate("/");
  };

  const handleEdit = () => {
    navigate("/editprofile");
  };

  const ProfileCard = () => (
    <Card border="danger" style={{ backgroundColor: "#151515" }}>
      <Card.Body>
        <Card.Title className="text-danger mb-3">My Profile</Card.Title>
        <ListGroup variant="flush">
          <ListGroup.Item
            style={{ backgroundColor: "#151515" }}
            className="text-danger border-0"
          >
            <FaUser /> Name:{" "}
            <span className="text-light">
              {firstname} {lastname}
            </span>
          </ListGroup.Item>
          <ListGroup.Item
            style={{ backgroundColor: "#151515" }}
            className="text-danger border-0"
          >
            <FaBirthdayCake /> Date of Birth:{" "}
            <span className="text-light">{dob ? dob : "Not Set"}</span>
          </ListGroup.Item>
          <ListGroup.Item
            style={{ backgroundColor: "#151515" }}
            className="text-danger border-0"
          >
            <FaMapMarkerAlt /> Gender:{" "}
            <span className="text-light">{gender ? gender : "Not Set"}</span>
          </ListGroup.Item>
          <ListGroup.Item
            style={{ backgroundColor: "#151515" }}
            className="text-danger border-0"
          >
            <FaPhone /> Phone Number:{" "}
            <span className="text-light">
              {number === null || number === "" ? (
                "Not Set"
              ) : (
                <>
                  {number}
                  {verified ? (
                    <VscVerified />
                  ) : (
                    <Link
                      className="link-danger"
                      onClick={() => setShowOtp(true)}
                    >
                      <VscUnverified />
                    </Link>
                  )}
                </>
              )}
            </span>
          </ListGroup.Item>
          <ListGroup.Item
            style={{ backgroundColor: "#151515" }}
            className="text-danger border-0"
          >
            <FaEnvelope /> Email: <span className="text-light">{email}</span>
          </ListGroup.Item>
          <ListGroup.Item
            style={{ backgroundColor: "#151515" }}
            className="text-danger border-0"
          >
            <FaRegFileAlt /> GST Number:{" "}
            <span className="text-light">{gst ? gst : "Not Set"}</span>
          </ListGroup.Item>
        </ListGroup>
        <Button variant="danger" className="mt-3" onClick={handleEdit}>
          Edit
        </Button>
      </Card.Body>
    </Card>
  );

  const OffcanvasMenu = () => (
    <Offcanvas
      show={showOffcanvas}
      onHide={handleToggleOffcanvas}
      placement="start"
    >
      <Offcanvas.Header
        closeButton
        closeVariant="white"
        style={{ backgroundColor: "#151515" }}
      >
        <Offcanvas.Title className="text-danger">Menu</Offcanvas.Title>
      </Offcanvas.Header>
      <Offcanvas.Body style={{ backgroundColor: "#151515" }}>
        <Card
          border="danger"
          className="mb-3"
          style={{ backgroundColor: "#151515" }}
        >
          <Card.Body>
            <Card.Title className="text-danger">Welcome!</Card.Title>
            <Card.Text className="text-light">
              {firstname} <br />
              {email}
            </Card.Text>
          </Card.Body>
        </Card>
        <Nav defaultActiveKey="/home" className="flex-column">
          <NavItem>
            <NavLink
              as={Link}
              onClick={(e) => {
                e.preventDefault();
                handleToggleOffcanvas();
                navigate("/profile");
              }}
              className="link-danger"
            >
              <FaUser /> My Profile
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              as={Link}
              onClick={(e) => {
                e.preventDefault();
                handleToggleOffcanvas();
                navigate("/orders");
              }}
              className="link-danger"
            >
              <FaBox /> My Orders
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              as={Link}
              onClick={(e) => {
                e.preventDefault();
                handleToggleOffcanvas();
                navigate("/addresses");
              }}
              className="link-danger"
            >
              <FaMapMarkerAlt /> My Addresses
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              as={Link}
              onClick={(e) => {
                e.preventDefault();
                handleToggleOffcanvas();
                navigate("/coupons");
              }}
              className="link-danger"
            >
              <FaTag /> My Coupons
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              as={Link}
              onClick={(e) => {
                e.preventDefault();
                handleToggleOffcanvas();
                navigate("/tickets");
              }}
              className="link-danger"
            >
              <BiSupport /> My Tickets
            </NavLink>
          </NavItem>
          <Nav.Item className="mt-3 mx-3">
            <Button variant="danger" onClick={handleLogout}>
              <FaSignOutAlt /> Logout
            </Button>
          </Nav.Item>
        </Nav>
      </Offcanvas.Body>
    </Offcanvas>
  );

  const Sidebar = () => (
    <>
      {" "}
      <Card
        border="danger"
        className="mb-3"
        style={{ backgroundColor: "#151515" }}
      >
        <Card.Body>
          <Card.Title className="text-danger">Welcome!</Card.Title>
          <Card.Text className="text-light">
            {firstname} <br />
            {email}
          </Card.Text>
        </Card.Body>
      </Card>
      <Nav defaultActiveKey="/home" className="flex-column">
        <NavItem>
          <NavLink as={Link} to="/profile" className="link-danger">
            <FaUser /> My Profile
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink as={Link} to="/orders" className="link-danger">
            <FaBox /> My Orders
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink as={Link} to="/addresses" className="link-danger">
            <FaMapMarkerAlt /> My Addresses
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink as={Link} to="/coupons" className="link-danger">
            <FaTag /> My Coupons
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink as={Link} to="/tickets" className="link-danger">
            <BiSupport /> My Tickets
          </NavLink>
        </NavItem>
        <Nav.Item className="mt-3 mx-3">
          <Button variant="danger" onClick={handleLogout}>
            <FaSignOutAlt /> Logout
          </Button>
        </Nav.Item>
      </Nav>
    </>
  );

  return (
    <Container className="mt-5">
      <Row className="mx-2">
        <Col xs={12} className="d-block d-lg-none">
          <Button
            variant="dark"
            onClick={handleToggleOffcanvas}
            aria-controls="offcanvas-sidebar"
            aria-expanded={showOffcanvas}
            className="mb-3"
          >
            <FaBars /> Menu
          </Button>
        </Col>
        <Col
          xs={12}
          className="d-block d-lg-none border border-dark rounded p-3"
          style={{ backgroundColor: "#151515", minHeight: "55vh" }}
        >
          <ProfileCard />
        </Col>
      </Row>
      <Row className="mx-2">
        <Col
          lg={3}
          className="d-none d-lg-block border border-dark rounded p-3"
          style={{ backgroundColor: "#151515" }}
        >
          <Sidebar />
        </Col>
        <Col
          lg={8}
          className="d-none d-lg-block border border-dark rounded p-3 mx-4"
          style={{ backgroundColor: "#151515", minHeight: "55vh" }}
        >
          <ProfileCard />
        </Col>
      </Row>
      <OffcanvasMenu />
      <VerifyPhone show={showOtp} onHide={handleCloseOtp} />
    </Container>
  );
};

export default MyProfile;
